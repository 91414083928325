const auto_assign_team_icon = require("~/assets/images/icon-auto-assign-team.svg")
const auto_assign_chat_status_icon = require("~/assets/images/icon-auto-assign-chat-status.svg")

const autoAssignHelper = {
  eventStatus: (auto_assign_action_type) => {
    switch (auto_assign_action_type) {
      case "replied_assign_to_team":
        return {
          type: "assign_by_status",
          actor: "แชทบอทตอบแล้ว",
          action_text: "ส่งแชทให้",
          preview_message: "ส่งแชทอัตโนมัติ",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: "0055",
        }
      case "fallback_assign_to_team":
        return {
          type: "assign_by_status",
          actor: "แชทบอทตอบไม่ได้",
          action_text: "ส่งแชทให้",
          preview_message: "ส่งแชทอัตโนมัติ",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: "0056",
        }
      case "request_assign_to_team":
        return {
          type: "assign_by_status",
          actor: "เรียกสมาชิก",
          action_text: "ส่งแชทให้",
          preview_message: "ส่งแชทอัตโนมัติ",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: "0057",
        }
      case "greeting_assign_to_team":
        return {
          type: "assign_by_status",
          actor: "ลูกค้าเพิ่มเพื่อน",
          action_text: "ส่งแชทให้",
          preview_message: "ส่งแชทอัตโนมัติ",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: "0058",
        }
      case "ended_chat_assign_to_team":
        return {
          type: "assign_by_status",
          actor: "จบแชทแล้ว",
          action_text: "ส่งแชทให้",
          preview_message: "ส่งแชทอัตโนมัติ",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: "0059",
        }
      case "replied_end_chat":
        return {
          type: "end_chat",
          actor: "แชทบอทตอบแล้ว",
          action_text: "จบแชท",
          preview_message: "จบแชทแล้ว",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: null,
        }
      case "fallback_end_chat":
        return {
          type: "end_chat",
          actor: "แชทบอทตอบไม่ได้",
          action_text: "จบแชท",
          preview_message: "จบแชทแล้ว",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: null,
        }
      case "request_end_chat":
        return {
          type: "end_chat",
          actor: "เรียกสมาชิก",
          action_text: "จบแชท",
          preview_message: "จบแชทแล้ว",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: null,
        }
      case "greeting_end_chat":
        return {
          type: "end_chat",
          actor: "ลูกค้าเพิ่มเพื่อน",
          action_text: "จบแชท",
          preview_message: "จบแชทแล้ว",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: null,
        }
      case "ended_chat_end_chat":
        return {
          type: "end_chat",
          actor: "จบแชทแล้ว",
          action_text: "จบแชท",
          preview_message: "จบแชทแล้ว",
          icon: auto_assign_chat_status_icon,
          system_feedback_id: null,
        }
      case "assign_to_member":
        return {
          type: "assign_by_team",
          actor: "ส่งแชทอัตโนมัติ (ทีม)",
          action_text: "ให้",
          preview_message: "ส่งแชทอัตโนมัติ",
          icon: auto_assign_team_icon,
          system_feedback_id: "0054",
        }
      default:
        return {
          type: "assign_by_status",
          actor: "แชทบอท",
          action_text: "ส่งแชทให้",
          preview_message: "แชทบอทส่งแชท",
        }
    }
  },
}

export default ({ app }, inject) => {
  inject("autoAssignHelper", autoAssignHelper)
}
