import { render, staticRenderFns } from "./Tag.vue?vue&type=template&id=48045cc0&"
import script from "./Tag.vue?vue&type=script&lang=js&"
export * from "./Tag.vue?vue&type=script&lang=js&"
import style0 from "./Tag.vue?vue&type=style&index=0&id=48045cc0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextOverflow: require('/builds/oho/oho-web-app/components/TextOverflow.vue').default})
