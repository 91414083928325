import _ from "lodash"

const ROLE_LIST = [
  {
    id: 1,
    label: "เจ้าของธุรกิจ",
    value: "owner",
  },
  {
    id: 2,
    label: "ผู้จัดการ",
    value: "manager",
  },
  {
    id: 3,
    label: "แอดมิน",
    value: "admin",
  },
  {
    id: 4,
    label: "พนักงานขาย",
    value: "sale",
  },
  {
    id: 5,
    label: "ทีมงาน",
    value: "staff",
  },
  {
    id: 6,
    label: "เอเจนต์",
    value: "agent",
  },
]

export default ({ store }, inject) => {
  const is_allow_use_feature_sale_visibility = _.get(
    store,
    "state.business.is_allow_use_feature_sale_visibility",
    false
  )

  const role = {
    list: !is_allow_use_feature_sale_visibility
      ? ROLE_LIST.filter((role) => role.value !== "sale")
      : ROLE_LIST,
    roleDisplayName: (role) => {
      switch (role) {
        case "owner":
          return "เจ้าของธุรกิจ"
        case "manager":
          return "ผู้จัดการ"
        case "admin":
          return "แอดมิน"
        case "sale":
          return "พนักงานขาย"
        case "staff":
          return "ทีมงาน"
        case "agent":
          return "เอเจนต์"
        default:
          return null
      }
    },
    roleDescription: (role) => {
      switch (role) {
        case "owner":
          return "สำหรับเจ้าของธุรกิจหรือผู้ที่ได้รับมอบหมายให้จัดการระบบเสมือนเจ้าของ มีสิทธิ์สูงสุด สามารถใช้งานได้ทุกฟังก์ชั่น มองเห็นข้อมูลทั้งหมด"
        case "manager":
          return "สำหรับผู้จัดการระบบแทนเจ้าของธุรกิจ เห็นภาพรวมและวิเคราะห์ได้สามารถใช้งานได้ทุกฟังก์ชั่น มองเห็นข้อมูลทั้งหมด"
        case "admin":
          return "สำหรับหัวหน้าทีมดูแลลูกค้าที่ต้องกำกับทีมงานและเอเจนต์ สามารถจัดการระบบส่วนใหญ่ได้ มองเห็นข้อมูลทั้งหมด"
        case "sale":
          return "สำหรับพนักงานในทีมขายที่ทำงานร่วมกับแอดมิน สามารถใช้งานฟังก์ชั่นเกี่ยวกับการขายได้ มองเห็นข้อมูลลูกค้าของทีมที่ตนเองอยู่เท่านั้น"
        case "staff":
          return "สำหรับผู้ที่มีหน้าที่ดูแลลูกค้าและทำงานร่วมกับสมาชิกในธุรกิจ ใช้งานได้เฉพาะฟังก์ชั่นที่เกี่ยวข้อง มองเห็นข้อมูลทั้งหมด"
        case "agent":
          return "สำหรับผู้ที่มีหน้าที่ดูแลลูกค้าในช่วงขณะหนึ่งตามงานที่ได้รับมอบหมาย มองเห็นข้อมูลเฉพาะลูกค้าที่ถูกส่งแชทให้เท่านั้น"
        default:
          return null
      }
    },
  }

  inject("role", role)
}
