import _ from "lodash"

export default ({ store }, inject) => {
  const current_member_role = _.get(store, "state.oho_member.member.role")

  const contact = {
    visibility: (contact) => {
      if (current_member_role !== "sale") return true

      const current_member_id = _.get(store, "state.oho_member.member._id")
      const my_teams = _.get(store, "state.oho_member.member.teams", [])

      const assignee_member_id = _.get(contact, "assignee.member_id._id")
      const assign_to_member_id = _.get(contact, "assign_to.member_id._id")
      const assign_to_team_id = _.get(contact, "assign_to.team_id._id")

      const contact_owner_member_id = _.get(contact, "owner.member_id._id")
      const contact_owner_team_id = _.get(contact, "owner.team_id._id")

      const is_my_chat = assignee_member_id === current_member_id
      const is_assign_to_me = assign_to_member_id === current_member_id
      const is_assign_to_my_team = my_teams.includes(assign_to_team_id)

      const is_my_contact = contact_owner_member_id === current_member_id
      const is_my_team_contact = my_teams.includes(contact_owner_team_id)

      if (
        is_my_chat ||
        is_assign_to_me ||
        (!assign_to_member_id && is_assign_to_my_team) ||
        is_my_contact ||
        is_my_team_contact
      ) {
        return true
      } else {
        return false
      }
    },
  }

  inject("contact", contact)
}
