
import _ from "lodash"
_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

const auto_assign_chat_status_icon = require("~/assets/images/icon-auto-assign-chat-status.svg")
const event_code_template = [
  {
    event_code: "chat_assignment.assign_bot_to_request_by_contact",
    text: "<b>เรียกสมาชิก</b>",
    style: 1,
    type: "default",
    actor: null,
    actor_team: null,
  },
  {
    event_code: "chat_assignment.assign_bot_to_request_by_inbox",
    text: "<b>เรียกสมาชิก</b>",
    style: 1,
    type: "default",
    actor: null,
    actor_team: null,
  },
  {
    event_code: "chat_assignment.assign_bot_to_request_by_bot_inbox",
    text: "<b>เรียกสมาชิก</b>",
    style: 1,
    type: "default",
    actor: null,
    actor_team: null,
  },
  {
    event_code: "chat_assignment.assign_bot_to_agent_by_contact",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    event_code: "chat_assignment.assign_bot_to_agent_by_member",
    style: 5,
    type: "assign_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.assign_bot_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "assign_to",
    actor_team: "assign_to",
  },
  {
    event_code: "chat_assignment.assign_request_to_bot_by_member",
    style: 4,
    type: "end_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.assign_request_to_agent_by_member",
    style: 5,
    type: "assign_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.assign_request_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.assign_agent_to_bot_by_member",
    style: 4,
    type: "end_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.assign_agent_to_agent_by_member",
    style: 5,
    type: "assign_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.assign_agent_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.assign_me_to_bot_by_member",
    style: 4,
    type: "end_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.assign_me_to_agent_by_member",
    style: 5,
    type: "assign_chat",
    actor: "assign_by",
    actor_team: "assign_by",
  },
  {
    event_code: "chat_assignment.accept_assign_bot_to_agent_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.accept_assign_bot_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.accept_assign_request_to_agent_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.accept_assign_request_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.accept_assign_agent_to_agent_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.accept_assign_agent_to_me_by_member",
    style: 4,
    type: "accept_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.reject_assign_bot_to_agent_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.reject_assign_bot_to_me_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.reject_assign_request_to_agent_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.reject_assign_request_to_me_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.reject_assign_agent_to_agent_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.reject_assign_agent_to_me_by_member",
    style: 4,
    type: "reject_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.cancel_assign_bot_to_agent_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.cancel_assign_bot_to_me_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.cancel_assign_request_to_agent_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.cancel_assign_request_to_me_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.cancel_assign_agent_to_agent_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat_assignment.cancel_assign_agent_to_me_by_member",
    style: 4,
    type: "cancel_chat",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "chat.send_first_message_by_member",
    style: 4,
    type: "first_response",
    actor: "action_by",
    actor_team: "action_by",
  },
  {
    event_code: "case.open",
    text: "<b>เริ่มเคส</b>",
    style: 6,
    type: "default",
    actor: null,
    actor_team: null,
  },
  {
    event_code: "case.close",
    text: "<b>ปิดเคส</b>",
    style: 7,
    type: "default",
    actor: null,
    actor_team: null,
  },
  {
    event_code: "case.comment.created",
    style: 3,
    type: "comment",
    actor: null,
    actor_team: null,
  },
  {
    event_code: "chat_assignment.assign_bot_to_agent_by_contactAction",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
]

const auto_assign_event_code_template = [
  {
    auto_assign_action_type: "assign_to_member",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "replied_assign_to_team",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "fallback_assign_to_team",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "request_assign_to_team",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "greeting_assign_to_team",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "ended_chat_assign_to_team",
    style: 5,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "replied_end_chat",
    style: 8,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "fallback_end_chat",
    style: 8,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "request_end_chat",
    style: 8,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "greeting_end_chat",
    style: 8,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
  {
    auto_assign_action_type: "ended_chat_end_chat",
    style: 8,
    type: "assign_chat",
    actor: null,
    actor_team: null,
  },
]

const event_style_template = [
  {
    id: 1,
    color: "#E64949",
    bg_color: "#FFEEEE",
    border_color: "#FFEEEE",
    is_profile: false,
    icon: "account_circle",
    icon_class: "material-icons",
  },
  {
    id: 2,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    icon: "sticky_note_2",
    icon_class: "material-icons-outlined",
  },
  {
    id: 3,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    icon: "sticky_note_2",
    icon_class: "material-icons-outlined",
  },
  {
    id: 4,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: true,
  },
  {
    id: 5,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    icon: "arrow_forward",
    icon_class: "material-icons-outlined",
  },
  {
    id: 6,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    icon: "topic",
    icon_class: "material-icons-outlined",
  },
  {
    id: 7,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    icon: "check",
    icon_class: "material-icons-outlined",
  },
  {
    id: 8,
    color: "#292929",
    bg_color: "#ffffff",
    border_color: "#757575",
    is_profile: false,
    is_auto_assign: true,
    icon_image: auto_assign_chat_status_icon,
  },
]

export default {
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activity_list() {
      return _.filter(this.activities, (item) => {
        if (
          item.event_code !== "case.tags.add" &&
          item.event_code !== "case.tags.remove"
        ) {
          return item
        }
      })
    },
  },
  methods: {
    transformActivity(activity) {
      const is_auto_assign_event = _.get(activity, "auto_assign_action_type")
      let template = {}

      if (is_auto_assign_event) {
        template = _.find(auto_assign_event_code_template, {
          auto_assign_action_type: activity.auto_assign_action_type,
        })
      } else {
        template = _.find(event_code_template, {
          event_code: activity.event_code,
        })
      }

      const { activity_content, action_by } =
        this.translateActivityStatus(activity)
      let content_text = null
      let style = null

      if (template) {
        content_text = this.templateInterpolate(template.text, activity_content)
        style = _.find(event_style_template, { id: template.style })
      }
      return {
        data: activity,
        action_by,
        content_text,
        style,
        type: template.type || "default",
        actor: template.actor || null,
        actor_team: template.actor_team || null,
      }
    },
    templateInterpolate(template, content) {
      const compiled = _.template(template)
      const res = compiled.call(this, content)
      return res
    },
    translateActivityContent(actor_key, activity) {
      return {
        [actor_key]: {
          ...(_.get(activity, actor_key) || {}),
        },
      }
    },
    translateActivityStatus(activity) {
      let content = {
        activity_content: null,
        action_by: null,
      }
      switch (activity.event_code) {
        case "chat_assignment.assign_bot_to_agent_by_contact":
          content.activity_content = this.translateActivityContent(
            "assign_to_member_id",
            activity
          )
          content.action_by = activity["assign_to_member_id"]
          break
        case "chat_assignment.assign_bot_to_agent_by_member":
          content.activity_content = {
            ...this.translateActivityContent("assign_by_member_id", activity),
            ...this.translateActivityContent("assign_to_member_id", activity),
          }
          break
        case "chat_assignment.assign_bot_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "assign_by_member_id",
            activity
          )
          content.action_by = activity["assign_by_member_id"]
          break
        case "chat_assignment.assign_request_to_bot_by_member":
          content.activity_content = this.translateActivityContent(
            "assign_by_member_id",
            activity
          )
          content.action_by = activity["assign_by_member_id"]
          break
        case "chat_assignment.assign_request_to_agent_by_member":
          content.activity_content = {
            ...this.translateActivityContent("assign_by_member_id", activity),
            ...this.translateActivityContent("assign_to_member_id", activity),
          }
          break
        case "chat_assignment.assign_request_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "assign_by_member_id",
            activity
          )
          content.action_by = activity["assign_by_member_id"]
          break
        case "chat_assignment.assign_agent_to_bot_by_member":
          content.activity_content = this.translateActivityContent(
            "assign_by_member_id",
            activity
          )
          content.action_by = activity["assign_by_member_id"]
          break
        case "chat_assignment.assign_agent_to_agent_by_member":
          content.activity_content = {
            ...this.translateActivityContent("assign_by_member_id", activity),
            ...this.translateActivityContent("assign_to_member_id", activity),
          }
          break
        case "chat_assignment.assign_agent_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "assign_by_member_id",
            activity
          )
          content.action_by = activity["assign_by_member_id"]
          break
        case "chat_assignment.assign_me_to_bot_by_member":
          content.activity_content = this.translateActivityContent(
            "assign_by_member_id",
            activity
          )
          content.action_by = activity["assign_by_member_id"]
          break
        case "chat_assignment.assign_me_to_agent_by_member":
          content.activity_content = {
            ...this.translateActivityContent("assign_by_member_id", activity),
            ...this.translateActivityContent("assign_to_member_id", activity),
          }
          break
        case "chat_assignment.accept_assign_bot_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.accept_assign_bot_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.accept_assign_request_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.accept_assign_request_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.accept_assign_agent_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "assign_to_member_id",
            activity
          )
          content.action_by = activity["assign_to_member_id"]
          break
        case "chat_assignment.accept_assign_agent_to_agent_by_membe":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.accept_assign_agent_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.reject_assign_bot_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.reject_assign_bot_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.reject_assign_request_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.reject_assign_request_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.reject_assign_agent_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.reject_assign_agent_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.cancel_assign_bot_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.cancel_assign_bot_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.cancel_assign_request_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.cancel_assign_request_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.cancel_assign_agent_to_agent_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.cancel_assign_agent_to_me_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat.send_first_message_by_member":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "case.comment.created":
          content.activity_content = this.translateActivityContent(
            "action_by_member_id",
            activity
          )
          content.action_by = activity["action_by_member_id"]
          break
        case "chat_assignment.assign_bot_to_agent_by_contactAction":
          content.activity_content = this.translateActivityContent(
            "assign_to_member_id",
            activity
          )
          content.action_by = activity["assign_to_member_id"]
          break
        default:
          content = {}
          break
      }
      return content
    },
  },
}
