import { render, staticRenderFns } from "./Sidenav.vue?vue&type=template&id=8475fdb6&scoped=true&"
import script from "./Sidenav.vue?vue&type=script&lang=js&"
export * from "./Sidenav.vue?vue&type=script&lang=js&"
import style0 from "./Sidenav.vue?vue&type=style&index=0&id=8475fdb6&prod&lang=scss&"
import style1 from "./Sidenav.vue?vue&type=style&index=1&id=8475fdb6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8475fdb6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BusinessImage: require('/builds/oho/oho-web-app/components/BusinessImage.vue').default,MemberProfileImage: require('/builds/oho/oho-web-app/components/Member/ProfileImage.vue').default,SettingUserSettingsDialog: require('/builds/oho/oho-web-app/components/Setting/UserSettingsDialog.vue').default,SettingUserEditModal: require('/builds/oho/oho-web-app/components/Setting/UserEditModal.vue').default,MemberProfileModal: require('/builds/oho/oho-web-app/components/Member/ProfileModal.vue').default,Onboarding: require('/builds/oho/oho-web-app/components/Onboarding.vue').default,SwitchBusiness: require('/builds/oho/oho-web-app/components/SwitchBusiness.vue').default})
