export default [
  {
    id: "0001",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: "เรียกสมาชิก",
  },
  {
    id: "0002",
    type: {
      id: "snackbar",
    },
    level: "success",
    behavior: "trasient",
    icon: "",
    image_circle: false,
    title: "",
    message: "ย้าย <b>{{ this.$textHelper.convertHtmlToString(contact_name) }}</b> มาที่แชทที่คุณกำลังดูแล",
  },
  {
    id: "0003",
    type: {
      id: "snackbar",
    },
    level: "success",
    behavior: "trasient",
    icon: "",
    image_circle: false,
    title: "",
    message:
      "ส่ง <b>{{ this.$textHelper.convertHtmlToString(contact_name) }}</b> ให้สมาชิก <b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 20) }}</b> ดูแล",
  },
  {
    id: "0004",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 15) }}</b>
          <span>{{ this.$textHelper.createTeamTag(assigner_team_name, assigner_team_color, assigner_team_logo) }}</span>
          <span>ส่งแชทให้คุณ</span>
        </div>
      `,
  },
  {
    id: "0005",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message:
      "<b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 15) }}</b> ส่งแชทให้คุณ",
  },
  {
    id: "0006",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 15) }}</b>
          <span>{{ this.$textHelper.createTeamTag(assigner_team_name, assigner_team_color, assigner_team_logo) }}</span>
          <span>ส่งแชทให้</span>
          <b>{{ this.$textHelper.displayTextContentWithLimit(assignee_name, 15) }}</b>
          <span>{{ this.$textHelper.createTeamTag(assignee_team_name, assignee_team_color, assignee_team_logo) }}</span>
        </div>
      `,
  },
  {
    id: "0007",
    type: {
      id: "snackbar",
    },
    level: "success",
    behavior: "trasient",
    icon: "",
    image_circle: false,
    title: "",
    message: "คุณได้จบแชทของ <b>{{ this.$textHelper.convertHtmlToString(contact_name) }}</b>",
  },
  {
    id: "0009",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 15) }}</b>
          <span>{{ this.$textHelper.createTeamTag(assignee_team_name, assignee_team_color, assignee_team_logo) }}</span>
          <span>จบแชทนี้แล้ว</span>
        </div>
      `,
  },
  {
    id: "0010",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: "{{ preview_message }}",
  },
  {
    id: "0047",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>{{ this.$textHelper.displayTextContentWithLimit(assignee_name, 15) }}</b>
          <span>{{ this.$textHelper.createTeamTag(assignee_team_name, assignee_team_color, assignee_team_logo) }}</span>
          <span>รับแชท</span>
        </div>
      `,
  },
  {
    id: "0048",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>{{ this.$textHelper.displayTextContentWithLimit(assignee_name, 15) }}</b>
          <span>{{ this.$textHelper.createTeamTag(assignee_team_name, assignee_team_color, assignee_team_logo) }}</span>
          <span>ปฏิเสธแชท</span>
        </div>
      `,
  },
  {
    id: "0049",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 15) }}</b>
          <span>{{ this.$textHelper.createTeamTag(assigner_team_name, assigner_team_color, assigner_team_logo) }}</span>
          <span>ยกเลิกส่งแชท</span>
        </div>
      `,
  },
  {
    id: "0050",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 15) }}</b>
          <span>{{ this.$textHelper.createTeamTag(assigner_team_name, assigner_team_color, assigner_team_logo) }}</span>
          <span>ส่งแชทให้</span>
          <span>{{ this.$textHelper.createTeamTag(new_assignee_team_name, new_assignee_team_color, new_assignee_team_logo) }}</span>
        </div>
      `,
  },
  {
    id: "0051",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 15) }}</b>
          <span>ส่งแชทให้</span>
          <span>{{ this.$textHelper.createTeamTag(new_assignee_team_name, new_assignee_team_color, new_assignee_team_logo) }}</span>
        </div>
      `,
  },
  {
    id: "0052",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-002",
      tmp_content_id: "tp-02-005",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: null,
    message: `
        <div class="notification-content">
          <span>คุณถูกเพิ่มเป็นสมาชิกทีม</span>
          <span>{{ this.$textHelper.createTeamTag(team_display_name, team_color_hex_code, team_logo_url) }}</span>
        </div>
      `,
  },
  {
    id: "0053",
    type: {
      id: "snackbar",
    },
    level: "success",
    behavior: "trasient",
    icon: "",
    image_circle: false,
    title: "",
    message:
      "ส่ง <b>{{ this.$textHelper.convertHtmlToString(contact_name) }}</b> ให้ทีม <b>{{ this.$textHelper.displayTextContentWithLimit(assigner_name, 20) }}</b> ดูแล",
  },
  {
    id: "0054",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: "<b>ส่งแชทอัตโนมัติ (ทีม)</b> ส่งแชทให้คุณ",
  },
  {
    id: "0055",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>แชทบอทตอบแล้ว</b>
          <span>ส่งแชทให้</span>
          <span>{{ this.$textHelper.createTeamTag(new_assignee_team_name, new_assignee_team_color, new_assignee_team_logo) }}</span>
        </div>
      `,
  },
  {
    id: "0056",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>แชทบอทตอบไม่ได้</b>
          <span>ส่งแชทให้</span>
          <span>{{ this.$textHelper.createTeamTag(new_assignee_team_name, new_assignee_team_color, new_assignee_team_logo) }}</span>
        </div>
      `,
  },
  {
    id: "0057",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>เรียกสมาชิก</b>
          <span>ส่งแชทให้</span>
          <span>{{ this.$textHelper.createTeamTag(new_assignee_team_name, new_assignee_team_color, new_assignee_team_logo) }}</span>
        </div>
      `,
  },
  {
    id: "0058",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>ลูกค้าเพิ่มเพื่อน</b>
          <span>ส่งแชทให้</span>
          <span>{{ this.$textHelper.createTeamTag(new_assignee_team_name, new_assignee_team_color, new_assignee_team_logo) }}</span>
        </div>
      `,
  },
  {
    id: "0059",
    type: {
      id: "toasts",
      tmp_title_id: "tp-01-001",
      tmp_content_id: "tp-02-001",
    },
    level: "",
    behavior: "trasient",
    image_circle: true,
    button_text: "ดูแชท",
    message: `
        <div class="notification-content">
          <b>จบแชทแล้ว</b>
          <span>ส่งแชทให้</span>
          <span>{{ this.$textHelper.createTeamTag(new_assignee_team_name, new_assignee_team_color, new_assignee_team_logo) }}</span>
        </div>
      `,
  },
]
