
import _ from "lodash"
import { mapState, mapMutations, mapActions } from "vuex"
import moment from "moment"
import ep from "~/api/endpoint"

export default {
  head() {
    const menu_name = _.get(this.current_menu, "name")
    const prefix = menu_name ? `${menu_name} - ` : ""
    const is_room_link = _.get(this.$route, "query.room")
    const og_title = is_room_link ? "กดลิงก์เพื่อเข้าห้องแชทลูกค้า" : "Oho Chat"

    return {
      title: `${prefix}Oho Chat`,
      meta: [
        {
          hid: "og:title",
          property: "og:title",
          content: og_title,
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: og_title,
        },
      ],
    }
  },
  data() {
    return {
      is_dev_mode: process.env.app_env === "development",
      show_feedback_survey: false,
      is_collapse_submenu: false,
      is_collapse_by_user: false,
      message_instance: null,
      widget_timeout_id: null,
    }
  },
  watch: {
    $mq: {
      immediate: true,
      handler(new_val) {
        if (!this.is_collapse_by_user) {
          if (new_val !== "desktop-wide") {
            this.is_collapse_submenu = true
          } else {
            this.is_collapse_submenu = false
          }
        }
      },
    },
    "$nuxt.isOffline": {
      handler(val) {
        if (val) {
          this.message_instance = this.$message.error({
            message: "ไม่พบการเชื่อมต่ออินเทอร์เน็ต",
            duration: 0,
          })
          this.$logger.error("Internet disconnected.")
        } else {
          this.message_instance.close()
          this.$logger.info("Internet connected.")
        }
      },
    },
    is_ws_error: {
      handler(val) {
        if (val) {
          this.$message.error({
            message: "พบปัญหาในการเชื่อมต่อ โปรดรีเฟรชเบราว์เซอร์ของคุณ",
            duration: 0,
          })
        }
      },
    },
    business: {
      immediate: true,
      handler(val) {
        const is_enabled_automation = _.get(
          val,
          "is_allow_use_feature_auto_assign"
        )
        const is_enabled_broadcast = _.get(
          val,
          "is_allow_use_feature_broadcast"
        )

        if (is_enabled_automation) {
          this.setActiveAutomationMenu()
        }
        if (is_enabled_broadcast) {
          this.setActiveBroadcastMenu()
        }
      },
    },
    case_menu_view_permission: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.setHideCaseSubMenu()
        }
      },
    },
  },
  computed: {
    ...mapState({
      menu_list: (state) => state.menu.menu_list,
      oho_member: (state) => state.oho_member,
      window_focused: (state) => state.window_focused,
      full_page: (state) => state.full_page,
      channels: (state) => state.channels,
      alert: (state) => state.alert,
      current_active_case: (state) => state.current_active_case,
      is_ws_error: (state) => state.websocket.is_ws_error,
      business: (state) => state.business,
    }),
    // ...mapGetters(["nav_menu"]),
    show_case_dialog: {
      set(val) {
        this.setShowCaseDialog(val)
      },
      get() {
        return this.$store.state.show_case_dialog
      },
    },
    show_end_case_dialog: {
      set(val) {
        this.setShowEndCaseDialog(val)
      },
      get() {
        return this.$store.state.show_end_case_dialog
      },
    },
    is_onboarding() {
      return this.$route.path.split("/")[3] === "onboarding"
    },
    route_path() {
      return this.is_onboarding
        ? `/${this.$route.path.split("/")[4]}`
        : this.$route.path
    },
    route_match_full_page() {
      const prefix_route_name = this.$route.name.split("business-biz_id-")[1]
      return this.full_page.includes(prefix_route_name)
    },
    current_menu() {
      const menu = this.menu_list.find(
        (m) => m.path_id === this.$route.path.split("/")[3]
      )
      return menu || {}
    },
    visible_submenu() {
      let visible = true

      if (
        ((_.get(this.current_menu, "sub_menu_list", []) == 0 ||
          this.route_match_full_page) &&
          !_.get(this.current_menu, "sub_menu_side", false)) ||
        !_.get(this.current_menu, "is_sub_menu_visible", true)
      ) {
        visible = false
      }

      return visible
    },
    case_menu_view_permission() {
      return this.$permission.validate("case-menu.view")
    },
  },
  async mounted() {
    if (!this.is_dev_mode && !this.$userAgent.is_mobile)
      this.setYpWidgetScript()

    document.addEventListener("visibilitychange", this.detectWindowFocus)
    this.sendCookiesToFlutter()
    this.onSetSocket()
    this.userActiveDayCount()
    this.handleShowCaseDialog()
    this.getOnlineStatusAggregate()

    if (
      this.$userAgent.is_mobile === false &&
      this.$userAgent.mac_os_system === false
    ) {
      document.body.classList.add("not-mac-os")
    }
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.detectWindowFocus)

    if (this.widget_timeout_id) {
      clearTimeout(this.widget_timeout_id)
    }
  },
  methods: {
    ...mapMutations([
      "setWindowFocused",
      "setShowCaseDialog",
      "setShowEndCaseDialog",
      "initMembersOnlineStatus",
      "initTeamsOnlineStatus",
      "setActiveAutomationMenu",
      "setActiveBroadcastMenu",
      "setHideCaseSubMenu",
    ]),
    ...mapActions([
      "setupSocket",
      "setFavicon",
      "sendCookiesToFlutter",
      "toggleCaseDialog",
    ]),
    detectWindowFocus() {
      if (document.visibilityState === "visible") {
        this.setWindowFocused(true)
        this.setFavicon("/favicon.png")
      }

      if (document.visibilityState === "hidden") {
        this.setWindowFocused(false)
      }
    },
    setYpWidgetScript() {
      const script = document.createElement("script")
      script.setAttribute(
        "src",
        "https://widget-platform.oho.chat/widgets/plugin.js"
      )
      document.body.appendChild(script)

      this.widget_timeout_id = setTimeout(() => {
        const widget_button = document.querySelector(".yp-logo")
        const button_list_el = document.querySelectorAll(
          ".yp-wrap .social-logo .logo-wrap"
        )

        if (widget_button) {
          widget_button.setAttribute("data-id", "app.widget")
          widget_button.setAttribute("data-tracking", "true")
        }

        if (button_list_el.length > 0) {
          const NAME_LIST = ["email", "line", "messenger"]
          button_list_el.forEach((item, index) => {
            item.setAttribute("data-id", `app.widget.${NAME_LIST[index]}`)
            item.setAttribute("data-tracking", "true")
          })
        }
      }, 2000)
    },
    getMemberAuthenticationInfo() {
      return {
        strategy: "jwt",
        accessToken: this.oho_member.accessToken,
      }
    },
    onSetSocket() {
      // const { member } = this.oho_member;
      // if (_.isEmpty(member)) return;

      const parsedOhoApiUrl = new URL(process.env.oho_api_url)
      const ohoApiPath = parsedOhoApiUrl.pathname

      // Connect to socket.io server
      const socket = this.$nuxtSocket({
        name: "main",
        path: ohoApiPath === "/" ? "/socket.io" : `${ohoApiPath}/socket.io`,
        transports: ["websocket"], // We don't support HTTP long-polling
        reconnection: true,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000,
        reconnectionAttempts: 10,
      })
      this.setupSocket([socket, this.getMemberAuthenticationInfo])
    },
    async userActiveDayCount() {
      // to collect active day for checking condion to pop up feedback survey
      const user_id = `${this.$cookies.get("oho_user_id")}`
      if (_.isEmpty(user_id)) return

      const res = await this.$ohoApi.$get(
        `${ep.user}/${user_id}/responded-survey`
      )
      const responded_survey = _.get(res, "responded_survey")
      if (responded_survey && responded_survey.includes("002")) return

      const today_date = `${moment().format("L")}`
      let active_count =
        JSON.parse(localStorage.getItem("oho_active_count")) || {}
      const user_active_count = _.get(active_count, `${user_id}`)

      if (_.isEmpty(active_count)) {
        active_count = { [user_id]: [today_date] }
      } else if (!user_active_count) {
        active_count[user_id] = [today_date]
      } else {
        // check only once per day
        const found = user_active_count.find((e) => e === today_date)
        if (found) return
        active_count[user_id].push(today_date)

        // Stop if tried to show survey popup over 6 times
        const survey_popup_count = JSON.parse(
          localStorage.getItem("oho_survey_popup_count")
        )
        let popup_count = 0
        const user_popup_count = _.get(survey_popup_count, `${user_id}`)

        if (!user_popup_count) popup_count = 1
        else popup_count = user_popup_count + 1

        if (popup_count > 6) return

        // Show survey every 5 log-in days
        let day_count = active_count[user_id].length
        if (day_count === 5 * popup_count) {
          this.show_feedback_survey = true
          active_count = _.omit(active_count, [user_id])
        }
      }

      localStorage.setItem("oho_active_count", JSON.stringify(active_count))
    },
    handleShowCaseDialog() {
      const case_id = _.get(this.$route.query, "case")
      if (case_id) {
        this.toggleCaseDialog({ status: true, case_id: case_id })
      }
    },
    async getOnlineStatusAggregate() {
      try {
        const res = await this.$ohoMemberApi.$get(ep.online_status_aggregate)
        const members = _.get(res, "members") || []
        const teams = _.get(res, "teams") || []

        if (members.length > 0) {
          this.initMembersOnlineStatus(members)
        }

        if (teams.length > 0) {
          this.initTeamsOnlineStatus(teams)
        }
      } catch (error) {
        this.$logger.error(_.get(error, "response.data"))
      }
    },
    toggleSubMenuSide() {
      this.is_collapse_submenu = !this.is_collapse_submenu
      this.is_collapse_by_user = true
    },
  },
}
