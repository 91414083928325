import _ from "lodash"
import moment from "moment"

const getFilterListCurrent = (filter_list, filter_type) => {
  return _.chain(filter_list)
    .find(["filter_type", filter_type])
    .get("filter_val", [])
    .value()
}
const getFilterValue = (filter_list, filter_type) => {
  return _.map(getFilterListCurrent(filter_list, filter_type), "value")
}

const isNotMatchChatListFilter = (
  contact_data,
  input_search,
  contact_channel_id,
  rootState
) => {
  const contact_display_name = _.get(contact_data, "display_name", "")
  const contact_first_name = _.get(contact_data, "first_name", "")
  const contact_last_name = _.get(contact_data, "last_name", "")
  const contact_email = _.get(contact_data, "email")
  const contact_phone = _.get(contact_data, "phone")
  const contact_tags = _.get(contact_data, "tags", [])
  const chat_tags = _.get(contact_data, "labels", [])
  const contact_tags_id = contact_tags.map((item) => item._id)
  const chat_tags_id = chat_tags.map((item) => item._id)

  const filtered_contact_tag = getFilterValue(
    rootState.filter.filter_chat_list,
    "contact_tag"
  )
  const filtered_chat_tag = getFilterValue(
    rootState.filter.filter_chat_list,
    "chat_tag"
  )

  const regex = new RegExp(input_search, "i")
  const is_display_name_matches = contact_display_name.match(regex)
  const is_first_name_matches = contact_first_name.match(regex)
  const is_last_name_matches = contact_last_name.match(regex)
  const is_email_matches = contact_email && contact_email === input_search
  const is_phone_matches = contact_phone && contact_phone === input_search

  const is_contact_not_in_channel_filter = !_.includes(
    rootState.checked_channels,
    contact_channel_id
  )

  const is_contact_not_in_tags_filter = !_.chain(filtered_contact_tag)
    .difference(contact_tags_id)
    .size()
    .eq(0)
    .value()

  const is_chat_not_in_tags_filter = !_.chain(filtered_chat_tag)
    .difference(chat_tags_id)
    .size()
    .eq(0)
    .value()

  const is_keyword_not_matches = !(
    is_display_name_matches ||
    is_first_name_matches ||
    is_last_name_matches ||
    is_email_matches ||
    is_phone_matches
  )

  return (
    is_contact_not_in_channel_filter ||
    is_contact_not_in_tags_filter ||
    is_chat_not_in_tags_filter ||
    is_keyword_not_matches
  )
}

const filterHelper = {
  handleSetDateRangeByDaysAgo: (day) => {
    let date_period = undefined
    if (day) {
      const start = moment()
        .startOf("date")
        .subtract(+day, "d")
        .format()
      const end = moment().endOf("date").format()
      date_period = [start, end]
    }
    return date_period
  },
  getFilterListCurrent,
  getFilterValue,
  isNotMatchChatListFilter,
}

export default ({ app }, inject) => {
  inject("filterHelper", filterHelper)
}
